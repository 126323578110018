import React from 'react';
import "../Style/dergi.css"

const dergiler = [
  { id: 1, name:"Hepimiz Birer ENTJ Olmalıyız", image: 'https://cdn.discordapp.com/attachments/862045295419981864/1117145760195158167/Turkiye_Tipoloji_Dergisi_7.png', link:'https://docdro.id/VLNjBLH' },
  { id: 2, name:"Fonksiyonlar ve Ejderhalar", image: 'https://cdn.discordapp.com/attachments/1118192911352352780/1139265043645599814/Kapak_1.png', link:'https://docdro.id/HkRZCT8'},
  { id: 3, name:"Güneşli OPS", image: 'https://cdn.discordapp.com/attachments/942128653976875010/1161363260751220836/Kapak_2.png?ex=653806d2&is=652591d2&hm=bbaa9d77629358aa2d8815c684e2d1834ac1a2e30dc2b88c0060b15f4923987c&', link:'https://docdro.id/adMEIYo'},

];

function Dergi() {
  return (
    <div>
    <div className="dergi-container">
      {dergiler.map((dergi) => (
        <div className="dergi-card" key={dergi.id}>
          <img src={dergi.image} alt={`Sayı ${dergi.id}`} />
          <p>Sayı - {dergi.id} | {dergi.name}</p>
          <a href={dergi.link} target="_blank" rel="noopener noreferrer" download>İndir</a>
          <h1>- {dergi.id} -</h1>
        </div>
      ))}
      
    </div>

    </div>
  );
}

export default Dergi;
