import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Anasayfa from './Components/Anasayfa';
import Iletisim from './Components/İletisim';
import Hakkimizda from './Components/Hakkimizda';
import Dergi from './Components/Dergi';
import Duyurular from './Components/Duyurular';
import Navbar from './Components/layout/Navbar';


function App() {
  return (
    <div className="App">
      <Router>
          <Navbar />
        <Routes>
          <Route path="/" exact element={<Anasayfa/>} />
          <Route path="/iletisim" element={<Iletisim/>} />
          <Route path="/hakkimizda" element={<Hakkimizda/>} />
          <Route path="/dergi" element={<Dergi/>} />
          <Route path="/duyurular" element={<Duyurular/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
