import React from 'react'
import "../Style/duyurular.css"

function Duyurular() {
  const duyurular = [
    {
      id: 1,
      baslik: '1. Sayımız Çıktı!',
      tarih: '10 Haziran 2023',
      aciklama: "Türkiye Tipoloji Dergisi'nin 1. sayısı çıktı."
    },
    {
      id: 2,
      baslik: 'Yazı Alımlarımız Başladı!',
      tarih: '10 Temmuz 2023',
      aciklama: 'Yazılarınızı 23 Temmuz tarihine kadar gönderebilirsiniz.'
    },
    {
      id: 3,
      baslik: "Yazı alımları için son gün 31 Temmuz'a uzatıldı!",
      tarih: '24 Temmuz 2023',
      aciklama: 'Yazılarınızı 31 Temmuz tarihine kadar gönderebilirsiniz.'
    },
   
    // ... diğer duyurular
  ];

  const duyurularTers = [...duyurular].reverse();

  return (
    <div className="duyurular-container">
      <h1 className="duyurular-title">Duyurular</h1>
      <div className="duyurular-list">
        {duyurularTers.map(duyuru => (
          <div className="duyuru" key={duyuru.id}>
            <h2 className="duyuru-baslik">{duyuru.baslik}</h2>
            <p className="duyuru-tarih">{duyuru.tarih}</p>
            <p className="duyuru-aciklama">{duyuru.aciklama}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Duyurular