import React from 'react';
import '../../Style/modal.css';

function Modal({ open, onClose }) {
  if (!open) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Kurallar</h2>
        <ul>
          <li><strong>Dil ve Üslup:</strong> Gönderilen yazılar Türk Dil Kurumunun belirttiği imla ve noktalama işaretleri kurallarına uygun olmalıdır. Yazılarda terimler hariç Türkçe olmayan kelimeler kullanılmamalıdır. Giriş, gelişme ve sonuç paragraflarının bulunduğu ve dilin göndergesel işlevinde kullanıldığı, derli bir üslup kullanılmalıdır.</li>
          <li><strong>Konu Seçimi:</strong> Yazıların konuları tipoloji çerçevesinde olmalıdır. Tartışılacak konular usulünce tartışılmalıdır.</li>
          <li><strong>Kaynakça:</strong> Kaynakça net olmalıdır: Yapılan alıntıların nereden geldiği, çeviriyse orijinal metin, yararlanılan kaynaklar açık bir şekilde belirtilmelidir.</li>
          <li><strong>Bilgi Doğruluğu:</strong> Herhangi bir bilgi yanlışı ya da dikkat hatasından kaçınılmalıdır. Bu, yazınızın reddedilmesine sebep olabilir.</li>
          <li><strong>Nesnel Bilgi Sunumu:</strong> Akıl yürütme ve dolaylı olarak varılan sonuçların nesnel bilgi olarak sunulması kesinlikle yasaktır. Argümanlar çerçevesinde ya da kişisel görüş olarak paylaşılabilmesi; tartışma ortamı yaratılması uygundur.</li>
          <li><strong>Saygı:</strong> Herhangi bir tipolojiyi, kişiyi, topluluğu ya da görüşü aşağılamak, hakaret etmek gibi davranışlar yasaktır. Eleştiri paylaşılabilir.</li>
          <li><strong>İntihal:</strong> İntihal yasaktır. Orijinal çalışmaların teşvik edildiği bu platformda, başkalarının çalışmalarının doğrudan kopyalanması kabul edilemez.</li>
          <li><strong>İdeolojik Propaganda:</strong> Herhangi bir şekilde nefret veya kin güden, zarar verici ideoloji ya da düşüncelerin propagandası, sunumu, tanıtımı ve benzerileri yasaktır.</li>
          <li><strong>Argümanlar:</strong> Temellendirilmemiş bir argüman kullanılamaz. Her iddia, uygun şekilde kaynaklandırılmış ve desteklenmiş olmalıdır.</li>
          <li><strong>Ortamın Korunması:</strong> Tipoloji toplulukları için nezih, huzurlu ve insanların fikirlerini ifade edebilecekleri bir ortam oluşturmaya çalıştığımı için bu ortamı bozacak söylemleri içeren yazılar kabul edilmeyecektir.</li>
          <li><strong>E-Posta:</strong> Yazınızın başında yazının konusu, yazınızın sonunda isim-soyisim, kullanılacaksa mahlas ve/veya sunucu adı yer almalıdır. İsim ve soyisminizin görünüp görünmeyeceğini belirtiniz.</li>
        </ul>
        <button onClick={onClose}>Onaylıyorum</button>
      </div>
    </div>
  );
}

export default Modal;
