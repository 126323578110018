import React from 'react'
import { Link } from "react-router-dom"
import '../../Style/navbar.css';


function Navbar() {
  return (
    <div className='navbar'>
        <h1>Türkiye Tipoloji Dergisi</h1>
      <Link to="/" className='ana'>Anasayfa</Link>
      <Link to="/hakkimizda" className='kapa'>Hakkımızda</Link>
      <Link to="/duyurular" className='duy'>Duyurular</Link>
      <Link to="/iletisim" className='ama'>İletişim</Link>
      <Link to="/dergi" className='derg'>Dergi</Link>
    </div>
  )
}

export default Navbar