import React, { useState } from 'react';
import "../Style/iletisim.css"
import Modal from './common/Modal';
import emailjs from 'emailjs-com';
import '@fortawesome/fontawesome-free/css/all.min.css';


function İletisim() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // emailjs.send(serviceID, templateID, templateParams, userID)
    emailjs.send('service_ptkog0i', 'template_mynbtgh', { name, email, message }, 'kYzFCQwOmRVkSt5MH')
      .then(function(response) {
        console.log('Başarılı!', response.status, response.text);
        alert("Mesajınız Gönderildi.");
      }, function(err) {
        console.log('Başarısız...', err);
      });
  }

  return (
    
    <form onSubmit={handleSubmit} className="form-container">
      <div className="social-icons">
        <a href="https://www.instagram.com/turkiyetipolojidergisi/">
          <i className="fab fa-instagram sosyalikon"></i>
        </a>
        <a href="https://www.twitter.com/turkiyetipoloji">
          <i className="fab fa-twitter sosyalikon"></i>
        </a>
        <a href="mailto:turkiyetipolojidergisi@gmail.com">
          <i className="far fa-envelope"></i>
        </a>
      </div>
      <h1>Yazılarınızı göndermek için:</h1>
      <input 
        type="text" 
        placeholder="İsim" 
        value={name} 
        onChange={(e) => setName(e.target.value)} 
      />

      <input 
        type="email" 
        placeholder="E-posta" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
      />

      <textarea 
        placeholder="Mesaj" 
        value={message} 
        onChange={(e) => setMessage(e.target.value)} 
      />

      <div className="agreement">
        <input 
          type="checkbox" 
          checked={agreed} 
          onChange={(e) => setAgreed(e.target.checked)} 
        />
        <label onClick={() => setIsModalOpen(true)}><strong>Kuralları</strong> okudum ve onaylıyorum.</label>
      </div>

      <button type="submit" disabled={!agreed}>Gönder</button>

      <Modal 
        open={isModalOpen} 
        onClose={() => {setIsModalOpen(false); setAgreed(true);}} 
      />
       
    </form>
  )
}

export default İletisim;
