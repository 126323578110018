import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../Style/kapsam.css"
import Modal from "./common/Modal2"

function Hakkimizda() {

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };


  return (
    <div className='kapsam'>

      <h1>AMAÇ</h1>
      <p> Türkiye'de tipoloji ile ilgilenen insanları ayrım gözetmeksizin tek bir çatı 
          altında toplamak birincil amacımızdır. Bu hususta insanlara huzurlu, nezih ve 
          konu kapsamında tüm düşüncelerini aktarabilecekleri; toplulukta kendilerine 
          yer edinebilecekleri bir ortam yaratmayı ve her türlü tipolojinin dahil 
          olabileceği büyük bir bilgi havuzu oluşturmayı hedefliyoruz. Bu bilgi 
          havuzuna katkıda bulunmak için 'turkiyetipolojidergisi@gmail.com' adresine 
          <Link to="#" className='linkSt' onClick={openModal}> Kurallar</Link>
          'a uygun şekilde 
          mail atabilir veya <Link to="/iletisim" className='linkSt'>İletişim </Link> 
          sayfası üzerinden yazılarınızı gönderebilirsiniz. 
          
          </p>


      <h1>KAPSAM</h1>

      <p>Türkiye Tipoloji Dergisi; tipolojiye dair her alanda faaliyet gösteren, 
        araştırmalar yapan ve bu alanı geliştirmeyi hedefleyen açık fikirli insanları
         bünyesinde barındıran bir dergidir. Bu dergi; tipoloji alanında birliği ve 
         beraberliği sağlayan, güvenilir bilgilerin ve sağlam düşüncelerin, oluşumların 
         yer aldığı bir yayın organıdır. </p>

         <Modal open={modalOpen} onClose={closeModal} />

    </div>
  )
}

export default Hakkimizda;
