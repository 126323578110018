import React from 'react'
import "../Style/anasayfa.css"

function Anasayfa() {
  return (
    <div className="content">

      <h1 className="title">Türkiye Tipoloji Dergisi
    <div className="aurora">
      <div className="aurora__item"></div>
      <div className="aurora__item"></div>
      <div className="aurora__item"></div>
      <div className="aurora__item"></div>
    </div>
  </h1>
  <p class="subtitle">Kendi araştırmanızı yazın, başkalarınınkini keşfedin</p>
</div>
  )
}

export default Anasayfa